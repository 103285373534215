<template>
  <div>
    <b-list-group class="mb-1">
      <b-list-group-item
        v-for="line in purchasing_request.lines"
        :key="line.id"
      >
        <b-row>
          <b-col>
            <small class="text-danger">Ürün Tipi</small>
            <div class="font-weight-bold">
              {{ line.product_type }}
            </div>
          </b-col>
          <b-col>
            <small class="text-danger">Ürün Grubu</small>
            <div class="font-weight-bold">
              {{ line.product_group }}
            </div>
          </b-col>
          <b-col>
            <small class="text-danger">Miktar</small>
            <div class="font-weight-bold">
              {{ line.quantity }} {{ line.product_unit }}
            </div>
          </b-col>
          <b-col>
            <small class="text-danger">Açıklama</small>
            <div class="font-weight-bold">
              {{ line.content }}
            </div>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue'

export default {
  name: 'RequestLines',
  components: {
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    purchasing_request() {
      return this.$store.getters['purchasingRequests/getPurchasingRequest']
    },
  },
}
</script>

<style scoped>

</style>
